import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "LARAVEL MESSAGING PACKAGE",
  "author": "tzookb",
  "type": "post",
  "date": "2018-02-27T11:51:16.000Z",
  "url": "/2018/02/laravel-messaging-package/",
  "categories": ["Uncategorized"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Client:Open Source`}</p>
    <p>{`Date:06/15`}</p>
    <p>{`Launch Project`}</p>
    <p>{`While creating the project: `}{`“`}{`Bloompy `}{`–`}{` travel social`}{`”`}</p>
    <p>{`we needed a messaging system for our users that will have those major features: group messaging, private messaging, message statuses etc.`}</p>
    <p>{`So we browsed the web quite a bit, and simply couldn`}{`’`}{`t find any good package with those features, then we created our package:`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/tzookb/tbmsg"
      }}>{`https://github.com/tzookb/tbmsg`}</a></p>
    <p>{`This package worked just great for us, unfortunately we don`}{`’`}{`t have time to continue developing it as the project was closed :/`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      